import React, { Component } from 'react';
import SlideShow from './slideshow/slideshow.js';
import QuickLink from './mainlink/mainlink.js';
import MiddleText from './middletext/middletext.js';
import AdAndSocial from './adandsocial/adandsocial.js';

class HomePage extends Component {



    render() {
        return(
            <React.Fragment>

                <SlideShow />
                <div className="col-md-12" style={{padding: '0px 20px', marginTop : '30px'}}>
                    <QuickLink />
                    <MiddleText />
                    <AdAndSocial />
                </div>

            </React.Fragment>
        )
    }

}

export default HomePage;