import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
// import InstagramEmbed from 'react-instagram-embed';
import './style.css';

class AdAndSocial extends Component {
    constructor() {
        super();
        this.state = {
            img1: null,
            img1_alt: 'alt',
            img2: null,
            img2_alt: 'alt',
            social_loading: false,
            social_block: null
        };
    }

    componentDidMount() {
        // Get deliverance imgs and instagram feed
        const fetch_option = {
            method: 'GET'
        }

        // const url_home_img = 'http://192.168.99.100/api/deliverance/getHomeImages';
        const url_home_img = '/api/deliverance/getHomeImages';

        fetch(url_home_img, fetch_option)
        .then(response => response.json())
        .then(data => {
            if (data.success){
                this.setState({
                    img1: data.imgs.img_4.filePath,
                    img1_link: data.imgs.img_4.resourceLink,
                    img2: data.imgs.img_2.filePath,
                    img2_link: data.imgs.img_2.resourceLink
                })
                // console.log("hello", data.imgs.img_4)
            }
        })
        .catch(error => { console.log(error) });

        // const url_insta = 'http://localhost:3000/test';
         const url_insta = '/api/social/instagram/getlatestfeed';
        // const url_insta = 'https://cors-anywhere.herokuapp.com/https://union.arizona.edu/api/social/instagram/getlatestfeed';
        // const url_insta = './json';

        fetch(url_insta,fetch_option)
        .then(response => response.json())
        .then(data => {
            if (data.success){
                // let social_block = <InstagramEmbed
                //                         url={data.feed.link}
                //                         maxWidth={500}
                //                         hideCaption={true}
                //                         containerTagName='div'
                //                         protocol=''
                //                         injectScript
                //                         onLoading={() => {}}
                //                         onSuccess={() => {}}
                //                         onAfterRender={() => {}}
                //                         onFailure={() => {}}
                //                     />
                // let img_width = data.feed.low_img.width;

                let social_block = 
                                <div className="wrap-su-instagram" style={{width:"358px"}}>

                                    <div className="wrap-su-insta-header">
                                        <a 
                                        className="su-insta-user-img" 
                                        href={'https://www.instagram.com/stories/' + data.feed.username} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        >
                                            <img src={data.feed.user_img} alt="instagram user profile" />
                                        </a>
                                        <div className="su-insta-prof-link">
                                            <a className="su-insta-username" href={'https://www.instagram.com/' + data.feed.username} target="_blank" rel="noopener noreferrer">{data.feed.username}</a>
                                            <a className="su-insta-location" href="https://www.instagram.com/explore/locations/9103334/arizona-student-unions" target="_blank" rel="noopener noreferrer">Arizona Student Union</a>
                                        </div>
                                        <div className="wrap-insta-profile-btn">
                                            <a 
                                            href={'https://www.instagram.com/' + data.feed.username} 
                                            className="btn btn-primary" 
                                            target="_blank"
                                            rel="noopener noreferrer">
                                                View Profile
                                            </a>
                                        </div>
                                    </div>
                
                                    <a className="su-insta-feed-img" href={data.feed.link} target="_blank" rel="noopener noreferrer" >
                                        
                                        { data.feed.image.includes('video') || data.feed.image.includes('reel') ?
                                        <video alt="instagram feed" width="358px" muted="true" autoplay="true" loop="true"><source src={data.feed.low_img} type="video/mp4"></source></video>
                                        :   
                                        <img src={data.feed.low_img} alt="instagram feed" /> 
                                        }
                                    </a>

                                    <div className="su-insta-bottom">
                                        <a className="su-insta-view-more" href={'https://www.instagram.com/' + data.feed.username} target="_blank" rel="noopener noreferrer">View More on Instagram</a>
                                        <MDBIcon fab icon="instagram" />
                                    </div>

                                </div>

                this.setState({
                    social_loading: true,
                    social_block : social_block
                })
            }
        })
        .catch(error => { console.log(error) });
    }

    printLoading(width) {
        return(
            <div style={{width: width, height: '100%', textAlign: 'center'}}>
                <div 
                className="spinner-border text-danger" 
                role="status"
                style={{
                    marginTop: '100px',
                    marginBottom: '40px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '5rem',
                    height: '5rem'
                }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    render() {
        return(
            <MDBRow className="wrap-adandsocial">

                <MDBCol className="wrap-deliverance-img">
                    {
                        this.state.img1 == null ?
                        this.printLoading('358px') :
                        (
                            this.state.img1_link === '' ?
                            (
                                <img src={this.state.img1} alt={this.state.img1_alt} />
                            ) :
                            (
                                <a href={this.state.img1_link} target="_blank" rel="noopener noreferrer">
                                    <img src={this.state.img1} alt={this.state.img1_alt} />
                                </a>
                            )
                        )
                    }
                </MDBCol>

                <MDBCol className="wrap-deliverance-img">
                    {
                        this.state.img2 == null ?
                        this.printLoading('358px') :
                        (
                            this.state.img2_link === '' ?
                            (
                                <img src={this.state.img2} alt={this.state.img2_alt} />
                            ) :
                            (
                                <a href={this.state.img2_link} target="_blank" rel="noopener noreferrer">
                                    <img src={this.state.img2} alt={this.state.img2_alt} />
                                </a>
                            )
                        )
                    }
                </MDBCol>

                <MDBCol>
                    {  
                        this.state.social_loading ?
                        this.state.social_block
                        :
                        this.printLoading('358px')
                    }
                </MDBCol>

            </MDBRow>
        );
    }
}

export default AdAndSocial;