import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';   
import React from 'react';
import ReactDOM from 'react-dom';
// import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './index.css';
import HomePage from './components/homepage';
import * as serviceWorker from './serviceWorker';

if (document.getElementById('su_homepage')){
    ReactDOM.render(<HomePage />, document.getElementById('su_homepage'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
