import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBView } from "mdbreact";
import React, { Component } from 'react';
import diningGuide from './images/SU_DiningGuide_WebBanner_23_V6.jpg';
import discover_su from './images/discover_su.d25fecd6.jpg';
import './style.css';

class SlideShow extends Component {

    render() {
		const imgs = [null, diningGuide, discover_su];
		const urls = [null, 'https://mealplans.arizona.edu/resources/23_Dining_Guide_Digital_V14.pdf', 'https://union.arizona.edu/involvement/'];
        const alts = [null, 'Dining guide', 'Discover the union'];
        
        return(
            <MDBContainer className="p-0">
                <MDBCarousel activeItem={1} length={imgs.length-1} showControls={true} showIndicators={true} interval={6000} className="z-depth-1">
                    <MDBCarouselInner>

                        {
                            imgs.map((img, index) => {
                                if (img != null){
                                    return (
                                        <MDBCarouselItem itemId={index}>
                                            <MDBView>
                                                <a href={urls[index]}><img className="d-block w-100" src={img} alt={alts[index]} /></a>
                                                {/* <MDBMask overlay="black-light" /> */}
                                            </MDBView>
                                            <MDBCarouselCaption>
                                                {/* <h3 className="h3-responsive">Light mask</h3>
                                                <p>First text</p> */}
                                            </MDBCarouselCaption>
                                        </MDBCarouselItem>
                                    )
                                }
                                else {
                                    return null
                                }
                            })
                        }

                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBContainer>
        );
    }
}

export default SlideShow;