import React, { Component } from 'react';
import { MDBRow, MDBCol } from "mdbreact";
import './style.css';

class MiddleText extends Component {
    render() {
        return(
            <MDBRow className="wrap-su-middletext">
                <MDBCol>

                    <h4>The University of Arizona's Living Room</h4>

                    <p>
                        The kitchen and living room of the University of Arizona, where everyone can eat, play, relax, and get involved! With Arizona Catering Company, Arizona Dining, Rooftop Greenhouse, cooking workshops, events and more, we strive to provide a "home away from home" to balance the diverse educational, recreational, cultural and social needs of today's student.
                    </p>    

                    {/* <p>
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p> */}

                </MDBCol>
            </MDBRow>
        )
    }
}

export default MiddleText;