import React, { Component } from 'react';
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, } from "mdbreact";
import './style.css';
import NutritionImg from '../../../img/home_btn_nutrition.jpg';
import MealplanImg from '../../../img/home_btn_mealplan.jpg';
import HoursImg from '../../../img/home_btn_hours.jpg';
import FeedbackImg from '../../../img/home_btn_feedback.jpg';

class QuickLink extends Component {
    render() {
        return(
            <MDBRow style={{marginTop: '30px', marginBottom: '30px'}}>

                <MDBCol className="su-quicklink-col" style={{paddingRight: '7px'}}>
                    {/* <MDBCard className="su-quicklink-card" style={{backgroundColor: '#f39d16'}}> */}
                    <MDBCard className="su-quicklink-card" style={{background: 'url(' + MealplanImg + ')', backgroundSize: 'cover'}}>
                        <MDBCardBody>
                            <MDBCardTitle><span>Meal Plans</span></MDBCardTitle>
                            {/* <h5 className="card-subtitle">Designed for you</h5>
                            <MDBCardText>Select a meal plan that fits your needs.</MDBCardText> */}
                            <MDBBtn outline block href="/mealplans/new-students.php">Meal Plan Options</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol className="su-quicklink-col" style={{paddingLeft: '7px', paddingRight: '7px'}}>
                    {/* <MDBCard className="su-quicklink-card" style={{backgroundColor: '#0f5f66'}}> */}
                    <MDBCard className="su-quicklink-card" style={{background: 'url(' + HoursImg + ')', backgroundSize: 'cover'}}>
                        <MDBCardBody>
                            <MDBCardTitle><span>Hours</span></MDBCardTitle>
                            {/* <h5 className="card-subtitle">Open Daily</h5>
                            <MDBCardText>Visit us and experience all we have to offer.</MDBCardText> */}
                            <MDBBtn outline block href="/infodesk/hours/">Show Hours</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol className="su-quicklink-col feedback" style={{paddingLeft: '7px', paddingRight: '7px'}}>
                    {/* <MDBCard className="su-quicklink-card" style={{backgroundColor: '#aa451b'}}> */}
                    <MDBCard className="su-quicklink-card" style={{background: 'url(' + FeedbackImg + ')', backgroundSize: 'cover'}}>
                        <MDBCardBody>
                            <MDBCardTitle><span>Feedback</span></MDBCardTitle>
                            {/* <h5 className="card-subtitle">Tell Us!</h5>
                            <MDBCardText>We'd love to hear your comments and are happy to answer your questions.</MDBCardText> */}
                            <MDBBtn outline block href="/about/tellus/">Leave Feedback</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                <MDBCol className="su-quicklink-col nutrition" style={{paddingLeft: '7px'}}>
                    {/* <MDBCard className="su-quicklink-card" style={{backgroundColor: '#799c4c'}}> */}
                    <MDBCard className="su-quicklink-card" style={{background: 'url(' + NutritionImg + ')', backgroundSize: 'cover'}}>
                        <MDBCardBody>
                            <MDBCardTitle><span>Nutrition</span></MDBCardTitle>
                            {/* <h5 className="card-subtitle">Eat Well</h5>
                            <MDBCardText>We offer a variety of nutrient dense options and can accommodate your dietary needs.</MDBCardText> */}
                            <MDBBtn outline block href="http://nutrition.union.arizona.edu">Learn more about Nutrition</MDBBtn>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

            </MDBRow>
        )
    }
}

export default QuickLink;